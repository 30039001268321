<template>
  <div class="store-search">
    <div class="main-card">
      <div style="color: #b0b0b0" v-if="list.list && list.list.length != 0">
        "<span style="color: #ee4d2d"> {{ keyWord }}</span>
        "相关的商店
      </div>
      <div class="store-box" v-if="list.list && list.list.length != 0">
        <div class="store-item" v-for="item in list.list" :key="item.id">
          <div style="width: 300px; display: flex; align-items: center">
            <div>
              <el-avatar :size="size" :src="item.shop_avatar"></el-avatar>
            </div>
            <div style="margin-left: 10px">
              <div style="font-size: 14px; margin-bottom: 6px">
                {{ item.shop_name }}
              </div>
              <div style="font-size: 12px">{{ item.member_name }}</div>
            </div>
          </div>
          <div style="display: flex; align-items: center; width: 400px">
            <div
              style="
                border-left: 1px solid #b0b0b0;
                padding: 0 20px;
                margin-right: 10px;
                border-right: 1px solid #b0b0b0;
              "
            >
              <div style="display: flex; align-items: center">
                <svg
                  t="1662531505198"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="3326"
                  width="20"
                  height="20"
                >
                  <path
                    d="M799.20128 218.9312c-26.53184-26.53184-79.0016-48.26112-116.5312-48.26112h-102.4c0 37.7344-30.5664 68.25984-68.27008 68.25984s-68.27008-30.52544-68.27008-68.25984h-102.4c-37.5296 0-89.99936 21.72928-116.5312 48.26112L102.4 341.32992l136.52992 136.54016 34.14016-34.14016v409.6h477.85984v-409.6l34.14016 34.14016L921.6 341.32992 799.20128 218.9312z m-116.5312 566.13888H341.32992V716.8h341.34016v68.27008z m0-506.14272v369.60256H341.32992V278.92736l-102.4 102.4-39.99744-39.99744 102.4-102.4h93.07136c18.13504 31.13984 47.93344 54.46656 83.46624 63.7952V409.6h68.25984V302.72512c35.57376-9.32864 65.34144-32.65536 83.46624-63.7952h93.07136l102.4 102.4-39.99744 39.99744-102.4-102.4z"
                    p-id="3327"
                    fill="#ee4d2d"
                  ></path>
                </svg>
                <span style="margin-left: 10px; color: #ee4d2d">{{
                  item.goods_num
                }}</span>
              </div>
              <div
                style="
                  text-align: center;
                  font-size: 14px;
                  margin-top: 4px;
                  color: #b3b3b3;
                "
              >
                商品
              </div>
            </div>
            <div style="padding: 0 20px">
              <div style="display: flex; align-items: center">
                <svg
                  t="1662531835099"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="4477"
                  width="20"
                  height="20"
                >
                  <path
                    d="M720.1 853.9c-7.2 0-14.4-1.7-21-5.2L512 750.3l-187.1 98.4c-15.3 8-33.5 6.7-47.5-3.4-14-10.2-20.9-27.1-17.9-44.1l35.7-208.3-151.3-147.6c-12.4-12.1-16.8-29.8-11.4-46.2 5.3-16.4 19.3-28.2 36.4-30.7L378 338l93.5-189.5c7.7-15.5 23.1-25.1 40.4-25.1 17.3 0 32.8 9.6 40.4 25.1L646 338l209.2 30.4c17.1 2.5 31.1 14.2 36.4 30.7 5.3 16.4 1 34.2-11.4 46.2L728.8 592.8l35.7 208.3c2.9 17-4 33.9-17.9 44.1-7.9 5.8-17.2 8.7-26.5 8.7zM207.4 423.4l135.3 131.9c10.6 10.4 15.5 25.3 13 39.9l-31.9 186.3L491 693.6c13.1-6.9 28.8-6.9 42 0l167.3 87.9-31.9-186.3c-2.5-14.6 2.3-29.6 13-39.9l135.3-131.9-187-27.2c-14.7-2.1-27.4-11.4-33.9-24.7L512 202.1l-83.6 169.5c-6.6 13.3-19.3 22.5-34 24.7l-187 27.1z"
                    p-id="4478"
                    fill="#ee4d2d"
                  ></path>
                </svg>
                <span style="margin-left: 10px; color: #ee4d2d">{{
                  item.score
                }}</span>
              </div>
              <div
                style="
                  text-align: center;
                  font-size: 14px;
                  margin-top: 4px;
                  color: #b3b3b3;
                "
              >
                评价
              </div>
            </div>
          </div>
          <div>
            <el-button size="mini" plain @click="ToStore(item.id)"
              >查看商店</el-button
            >
          </div>
        </div>
      </div>
      <div style="color: #b0b0b0; text-align: center; padding: 30px 0" v-else>
        未找到 "<span style="color: #ee4d2d"> {{ keyWord }}</span>
        "相关的商店
      </div>
      <div>
        <div style="display: flex; justify-content: center; padding-top: 20px">
          <el-pagination
            :page-size="page_size"
            :current-page="page"
            background
            layout="prev, pager, next"
            :total="list.total"
            @current-change="swPage"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { searchStore } from "@/api/home.js";
export default {
  data() {
    return {
      page: 1,
      page_size: 10,
      list: "",
      size: 60,
      keyWord: "",
    };
  },
  created() {
    this.keyWord = this.$store.state.searchValue;
    this.getList();
  },
  methods: {
    getList() {
      searchStore({
        keyword: this.keyWord,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        if (res.code == 1) {
    
          this.list = res.data;
        }
      });
    },
    swPage(e) {
      this.page = e;
      this.getList();
    },
    ToStore(id) {
      window.scrollTo(0, 0);
      this.$router.replace({
        path: "storeHome",
        query: {
          shop_id: id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.store-search {
  min-height: 500px;
  background: #f5f5f5;
  padding: 30px 0;
  .store-box {
    .store-item {
      background: #fff;
      border-block: 4px;
      padding: 20px;
      margin-top: 20px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>